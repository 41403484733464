<template>
  <div class="mt-5">
    <div class="p-float-label _label"><label>{{ lang ? labels['_i18n'][lang][field] : labels[field] }}</label></div>
    <template v-if="lang">
      <Textarea
        v-model="store.entity['_i18n'][lang][field]"
        :class="{ 'p-invalid': isError }"
        :cols="cols"
        :disabled="disabled || isLock"
        :maxlength="maxlength"
        :rows="rows"
        style="width: 100%"
      />
    </template>
    <template v-else>
      <Textarea
        v-model="store.entity[field]"
        :class="{ 'p-invalid': isError }"
        :cols="cols"
        :disabled="disabled || isLock"
        :maxlength="maxlength"
        :rows="rows"
        style="width: 100%"
      />
    </template>
    <div>
      <small v-if="isError" class="p-error">
        {{
          lang
            ? v$.value['_i18n'][lang][field].required.$message.replace('Value', labels['_i18n'][lang][field])
            : v$.value[field].required.$message.replace('Value', labels[field]) }}
      </small>
    </div>
  </div>
</template>
<script setup>
import useElements from '@/components/elements_form/useElements'

const
  props = defineProps({
    form: Object,
    field: String,
    placeholder: String,
    lang: String,
    type: {
      type: String,
      default: 'text'
    },
    maxlength: {
      type: Number,
      default: 300
    },
    rows: {
      type: Number,
      default: 3
    },
    cols: {
      type: Number,
      default: 50
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'on'
    }
  }),
  
  { v$, isLock, store, submitted, labels, isError } = useElements({ props })

</script>
