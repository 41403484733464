import axios from 'axios'

const TIMEOUT = 1000000

const api = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_URL,
  headers: {
    common: {
      'Content-Type': 'application/json'
    }
  }
})

const logout = () => {
  localStorage.removeItem('token')
  console.log('Unauthorized!')
  window.location.replace('/login')
}

const
  getToken = () => localStorage.getItem('token'),
  onRequestSuccess = config => {
    // console.error('! LOG onRequestSuccess: ')
    const token = getToken()
    if (token) {
      if (!config.headers) {
        config.headers = {}
      }
      // config.headers.Authorization = `Bearer ${ token }`
      config.headers['x-access-token'] = token
    }
    // config.timeout = TIMEOUT
    // config.url = `${ process.env.VUE_APP_BACKEND_URL }${ config.url }`
    return config
  },

  onResponseError = (err) => {
    console.log('>>> onResponseError: ', err)
    const
      status = err?.status || err?.response?.status || null,
      code = parseInt(err.response && status),
      token = getToken()

    console.log('>>> token: ', token)
    if (!token || token === 'undefined') {
      logout()
      return Promise.reject(err)
    }

    if (code === 400 || code === 401 || code === 403) {
      logout()
    }
    return Promise.reject(err)
  },

  onResponse = (response) => {
    return response.data
  }

api.interceptors.request.use(onRequestSuccess)
api.interceptors.response.use(onResponse, onResponseError)

const setupAxiosInterceptors = () => {
  if (axios.interceptors) {
    axios.interceptors.request.use(onRequestSuccess)
    axios.interceptors.response.use(onResponse, onResponseError)
  }
}

export default api
export { setupAxiosInterceptors }
