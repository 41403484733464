<template>
  <div class="mt-5">
    <span class="p-float-label">
      <Calendar
        v-model="store.entity[field]"
        :class="{ 'p-invalid': isError }"
        :maxDate="birthday ? new Date(dayjs().subtract(21, 'years').year(), dayjs().month(), dayjs().date()) : null"
        :minDate="birthday ? new Date(1900, dayjs().month(), dayjs().date()) : null"
        :utc="true"
        autocomplete="off"
        dateFormat="dd.mm.yy"
      />
      <label :for="field">{{ labels[field] }}</label>
    </span>
    <div>
      <small v-if="isError" class="p-error">
        {{ v$.value[field].required.$message.replace('Value', labels[field]) }}
      </small>
    </div>
  </div>
</template>

<script setup>
import useElements from '@/components/elements_form/useElements'
import * as dayjs from 'dayjs'

const props = defineProps({
    form: Object,
    field: String,
    placeholder: String,
    maxlength: {
      type: Number,
      default: 256
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    birthday: {
      type: Boolean,
      default: false
    }
  }),

  { v$, isLock, store, submitted, labels, isError } = useElements({ props })

</script>
