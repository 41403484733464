import { defineStore } from 'pinia'
import useAPI from '@/composables/useAPI'

export default (code, customStore: null | Function = null) => {
  const API = useAPI(code)

  return defineStore(code, () => {
    const
      setEntity = async (entity) => {
        await API.setEntity(entity)
      },

      getEntities = async (params) => {
        await API.findAll(params)
      },

      getEntity = async (id) => {
        await API.findOne(id)
      },

      createEntity = async () => {
        return API.createUpdate()
      },

      deleteEntity = async (id) => {
        await API.remove(id)
      },

      getList = async () => {
        await API.findList()
      },

      { entities, pagination, entity, list } = API

    let customParams = {}
    if (customStore && typeof customStore === 'function') customParams = customStore()

    return { getEntities, getEntity, setEntity, createEntity, deleteEntity, getList, entities, pagination, entity, list, ...customParams }
  })
}
