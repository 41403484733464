<template>
  <div class="p-float-label _label mt-3"><label>{{ labels[field] }}</label></div>
  <Dropdown
    v-model="store.entity[field]"
    :class="{ 'p-invalid': v$.value[field] && v$.value[field].$invalid && submitted }"
    :disabled="disabled || isLock"
    :options="options"
    :placeholder="placeholder || `Select ${ capitalizeFirstLetter(field)}`"
    class="max-w-14rem"
    optionLabel="name"
    optionValue="value"
  />
  <div><small v-if="v$.value[field] && v$.value[field].$invalid && submitted" class="p-error">
    {{ v$.value[field].required.$message.replace('Value', capitalizeFirstLetter(field)) }}
  </small></div>
</template>


<script setup>
import { capitalizeFirstLetter } from '@/utils/utils'
import useElements from '@/components/elements_form/useElements'

const
  props = defineProps({
    form: Object,
    options: Array,
    field: String,
    placeholder: String,
    disabled: {
      type: Boolean,
      default: false
    }
  }),
  
  { v$, isLock, store, submitted, labels } = useElements({ props })

</script>
