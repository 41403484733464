import { createApp, reactive } from 'vue'
import App from './App.vue'
// import './registerServiceWorker'
import router from './router'

import { setupAxiosInterceptors } from './plugins/axios-instance'
import applyPrimeView from './plugins/primevue'
import applyMazUi from './plugins/maz-ui'
import applyCommon from './plugins/common'
import applyFontAwesome from './plugins/fontawesome'
import { createPinia } from 'pinia'

// load primevue styles
import 'primevue/resources/themes/lara-dark-teal/theme.css'
import 'primeicons/primeicons.css'                           // icons
import 'primeflex/primeflex.css'

import '@/assets/styles/styles.scss'

const app = createApp(App)

setupAxiosInterceptors()
applyPrimeView(app)
applyFontAwesome(app)
applyMazUi(app)
applyCommon(app)


app.config.globalProperties.$appState = reactive({ colorScheme: 'light' })

app
  .use(createPinia())
  .use(router)
  .mount('#app')
