<template>
  <div class="mt-5">
    <div class="p-float-label _label">
      <label :for="field">{{ labels[field] }}</label>
    </div>
    <SelectButton
      v-model="store.entity[field]"
      :class="{'p-invalid': v$.value[field] && v$.value[field].$invalid && submitted}"
      :disabled="disabled || isLock"
      :multiple="multiple"
      :optionLabel="isOptionObject ? optionLabel : null"
      :optionValue="isOptionObject ? optionValue : null"
      :options="options"
      :value="modelValue"
    />
    <div>
      <small v-if="v$.value[field] && submitted && v$.value[field].$invalid" class="p-error">
        {{ v$.value[field].required.$message.replace('Value', labels[field]) }}
      </small>
    </div>
  </div>
</template>

<script setup>
import useElements from '@/components/elements_form/useElements'

const
  props = defineProps({
    modelValue: [String, Boolean, Number],
    form: Object,
    field: String,
    options: Array,
    disabled: Boolean,
    multiple: Boolean,
    optionLabel: {
      type: String,
      default: 'name'
    },
    optionValue: {
      type: String,
      default: 'value'
    },
    isOptionObject: {
      type: Boolean,
      default: false
    }
  }),
  
  { v$, isLock, store, submitted, labels } = useElements({ props })

</script>
