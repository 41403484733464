<template>
  <component :is="layout">
    <slot />
  </component>
</template>
<script setup>
import AppLayoutDefault from '@/layouts/AppLayoutDefault.vue'
import { shallowRef, watch } from 'vue'
import { useRoute } from 'vue-router'

const layout = shallowRef(null) // markRaw(AppLayoutDefault)
const route = useRoute()
watch(
  () => route.meta,
  async () => {
    try {
      const component = await import(`@/layouts/${ route.meta.layout }.vue`)
      layout.value = component?.default || AppLayoutDefault
    } catch (e) {
      layout.value = AppLayoutDefault
    }
  },
  {
    // deep: true,
    immediate: false
  }
)
</script>
