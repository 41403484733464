import { computed } from 'vue'
import { useTemporaryStore } from '@/store/temporaryStore'

export default ({ props = {} }: any) => {
  const
    temporaryStore = useTemporaryStore(),
    { form } = props, // || temporaryStore.form,
    v$ = form ? form['v$'] : computed(() => temporaryStore.form['v$']),
    submitted = form ? form['submitted'] : computed(() => temporaryStore.form['submitted']),
    isLock = form ? form['isLock'] : computed(() => temporaryStore.form['isLock']),
    { store, labels, getError } = form || temporaryStore.form,
    isError = getError(props.lang, props.field)

  return { v$, isLock, store, submitted, labels, isError }
}
