<template>
  <div class="mt-5">
    <span class="p-float-label">
      <template v-if="lang">
        <InputNumber
          v-if="type === 'number'"
          v-model="store.entity['_i18n'][lang][field]"
          :autocomplete="autocomplete"
          :class="{ 'p-invalid': isError }"
          :currency="currency"
          :disabled="disabled || isLock"
          :mode="mode"
          :showButtons="showButtons"
          class="number-input"
        />
        <InputText
          v-else
          v-model="store.entity['_i18n'][lang][field]"
          :autocomplete="autocomplete"
          :class="{ 'p-invalid': isError }"
          :disabled="disabled || isLock"
          :type="type"
        />
        <label :for="field">{{ labels['_i18n'][lang][field] }}</label>
      </template>
        <template v-else>
          <InputNumber
            v-if="type === 'number'"
            v-model="store.entity[field]"
            :autocomplete="autocomplete"
            :class="{ 'p-invalid': isError }"
            :currency="currency"
            :disabled="disabled || isLock"
            :maxFractionDigits="maxFractionDigits"
            :minFractionDigits="minFractionDigits"
            :mode="mode"
            :showButtons="showButtons"
            class="number-input"
          />
        <InputText
          v-else
          v-model="store.entity[field]"
          :autocomplete="autocomplete"
          :class="{ 'p-invalid': isError }"
          :disabled="disabled || isLock"
          :type="type"
        />
        <label :for="field">{{ labels[field] }}</label>
      </template>
      <span v-if="suffix" class="ml-2">{{ suffix }}</span>
    </span>
    <div>
      <small v-if="isError" class="p-error">
        {{
          lang
            ? v$.value['_i18n'][lang][field].required.$message.replace('Value', labels['_i18n'][lang][field])
            : v$.value[field].required.$message.replace('Value', labels[field]) }}
      </small>
    </div>
  </div>
</template>
<script setup>
import useElements from '@/components/elements_form/useElements'

const
  props = defineProps({
    form: Object,
    field: String,
    placeholder: String,
    lang: String,
    showButtons: {
      type: Boolean,
      default: true
    },
    type: {
      type: String,
      default: 'text'
    },
    mode: String,
    currency: {
      type: String,
      default: 'USD'
    },
    suffix: {
      type: String,
      default: ''
    },
    maxlength: {
      type: Number,
      default: 256
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'on'
    },
    minFractionDigits: {
      type: Number,
      default: 0
    },
    maxFractionDigits: {
      type: Number,
      default: 0
    }
  }),
  
  { v$, isLock, store, submitted, labels, isError } = useElements({ props })
</script>
