import PrimeVue from 'primevue/config'
import Avatar from 'primevue/avatar'
// import Accordion from 'primevue/accordion'
// import AccordionTab from 'primevue/accordiontab'
// import AutoComplete from 'primevue/autocomplete'
import BadgeDirective from 'primevue/badgedirective'
import Button from 'primevue/button'
import Breadcrumb from 'primevue/breadcrumb'
import Checkbox from 'primevue/checkbox'
import Calendar from 'primevue/calendar'
import Chart from 'primevue/chart'
import Chip from 'primevue/chip'
import ConfirmationService from 'primevue/confirmationservice'
import ConfirmDialog from 'primevue/confirmdialog'
import Column from 'primevue/column'
// import ColumnGroup from 'primevue/columngroup'
import DataTable from 'primevue/datatable'
import Dropdown from 'primevue/dropdown'
import Dialog from 'primevue/dialog'
import Divider from 'primevue/divider'
import Image from 'primevue/image'
import InputText from 'primevue/inputtext'
import InputMask from 'primevue/inputmask'
import InlineMessage from 'primevue/inlinemessage'
import InputSwitch from 'primevue/inputswitch'
import InputNumber from 'primevue/inputnumber'
import OverlayPanel from 'primevue/overlaypanel'
import FileUpload from 'primevue/fileupload'
// import Editor from 'primevue/editor'
import Editor from '@tinymce/tinymce-vue'
import Fieldset from 'primevue/fieldset'
import Password from 'primevue/password'
import Row from 'primevue/row'
import MultiSelect from 'primevue/multiselect'
import Message from 'primevue/message'
import Ripple from 'primevue/ripple'
import RadioButton from 'primevue/radiobutton'
import ScrollPanel from 'primevue/scrollpanel'
import SelectButton from 'primevue/selectbutton'
import Tag from 'primevue/tag'
import TabView from 'primevue/tabview'
import TabPanel from 'primevue/tabpanel'
import Toast from 'primevue/toast'
import Tooltip from 'primevue/tooltip'
import ToastService from 'primevue/toastservice'
import Textarea from 'primevue/textarea'
import TriStateCheckbox from 'primevue/tristatecheckbox'

// import CodeHighlight from '../../atlantis-vue-1.0.1/src/AppCodeHighlight'

// import { EventBus } from 'primevue/utils'
import Chips from 'primevue/chips'

import 'chartjs-adapter-date-fns'
import ConfirmPopup from 'primevue/confirmpopup'

const applyPrimeView = (app) => {
  app.directive('tooltip', Tooltip)
  app.directive('badge', BadgeDirective)
  app.directive('ripple', Ripple)

  app.use(PrimeVue, { ripple: true })
  app.use(ToastService)
  app.use(ConfirmationService)

  // app.component('AutoComplete', AutoComplete)

  // app.component('Accordion', Accordion)
  // app.component('AccordionTab', AccordionTab)

  app.component('Avatar', Avatar)
  app.component('Button', Button)
  app.component('Breadcrumb', Breadcrumb)
  app.component('Chart', Chart)
  app.component('Chip', Chip)
  app.component('Checkbox', Checkbox)
  app.component('Column', Column)
  app.component('Chips', Chips)
  // app.component('ColumnGroup', ColumnGroup)
  app.component('ConfirmDialog', ConfirmDialog)
  app.component('ConfirmPopup', ConfirmPopup)
  app.component('Dialog', Dialog)
  app.component('Divider', Divider)
  app.component('Dropdown', Dropdown)
  app.component('Image', Image)
  app.component('InputNumber', InputNumber)
  app.component('InputText', InputText)
  app.component('InputMask', InputMask)
  app.component('InputSwitch', InputSwitch)
  app.component('OverlayPanel', OverlayPanel)
  app.component('Fieldset', Fieldset)
  app.component('FileUpload', FileUpload)
  app.component('Editor', Editor)
  app.component('Password', Password)
  app.component('MultiSelect', MultiSelect)
  app.component('Calendar', Calendar)
  app.component('DataTable', DataTable)
  app.component('Row', Row)
  app.component('Message', Message)
  app.component('InlineMessage', InlineMessage)
  app.component('RadioButton', RadioButton)
  app.component('ScrollPanel', ScrollPanel)
  app.component('SelectButton', SelectButton)
  app.component('TriStateCheckbox', TriStateCheckbox)
  app.component('Tag', Tag)
  app.component('TabView', TabView)
  app.component('TabPanel', TabPanel)
  app.component('Textarea', Textarea)
  app.component('Toast', Toast)

  // app.directive('code', CodeHighlight);
}


export default applyPrimeView
