<template>
  <div v-if="store.entity && isSidebar()" :class="['layout-inline-menu', {'layout-inline-menu-active': activeInlineProfile}]"> <!-- !isMobile() &&  -->
    <a class="layout-inline-menu-action" @click="onChangeActiveInlineMenu">
      <img v-if="store.entity.fileBase64 || store.entity.avatar" :src="store.entity.fileBase64 || store.entity.avatar" alt="avatar" style="width: 44px; height: 44px;">
      <span class="layout-inline-menu-text">{{ store.entity.name }}</span>
      <i class="layout-inline-menu-icon pi pi-angle-down"></i>
    </a>
    
    <transition name="layout-inline-menu-action-panel">
      <ul v-show="activeInlineProfile" class="layout-inline-menu-action-panel">
        <li class="layout-inline-menu-action-item">
          <a @click="onProfile">
            <i class="pi pi-user pi-fw"></i>
            <span>Profile</span>
          </a>
        </li>
        <li class="layout-inline-menu-action-item">
          <a @click="onLogout">
            <i class="pi pi-power-off pi-fw"></i>
            <span>Logout</span>
          </a>
        </li>
        <!--
        <li class="layout-inline-menu-action-item">
          <a>
            <i class="pi pi-cog pi-fw"></i>
            <span>Settings</span>
          </a>
        </li>
        -->
      </ul>
    </transition>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useAuthStore } from '@/store/auth'
import { useAdminUserStore } from '@/store/adminUsers'

const props = defineProps({
  menuMode: String,
  activeInlineProfile: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['change-inlinemenu'])

const
  storeAuth = useAuthStore(),
  store = useAdminUserStore(),
  router = useRouter()

console.log(':: AppInlineMenu')

const
  onChangeActiveInlineMenu = (e) => {
    emit('change-inlinemenu', e)
    e.preventDefault()
  },
  isSidebar = () => props.menuMode === 'sidebar',
  isDesktop = () => window.innerWidth > 991,
  isMobile = () => window.innerWidth <= 991

const
  onProfile = async () => {
    const { _id } = store.entity
    await router.push({ name: 'ProfileEdit', params: { id: _id } })
  },
  onLogout = async () => {
    await router.push({ name: 'LoginView' })
    await storeAuth.logout()
  }

</script>

