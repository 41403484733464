<template>
  <div class="field mb-5">
    <template v-if="fileSrc">
      <Image v-if="type === 'image'" :src="fileSrc" alt="Image" preview width="200" />
      <audio v-if="type === 'audio'" id="audio-preview" :src="fileSrc" controls />
      <a v-if="type === 'file'" :src="fileSrc" class="mt-3" href="#" target="_blank">
        {{ store.entity?.[props.field] }}
      </a>
    </template>
    <FileUpload
      :accept="accept || `${type}/*`"
      :auto="true"
      :chooseLabel="`Upload ${ capitalizeFirstLetter(field) }`"
      :customUpload="true"
      :disabled="disabled || isLock"
      class="p-button-link p-button-text"
      mode="basic"
      name="file"
      @uploader="onSelect"
    />
    <small v-if="v$.value[field] && submitted && v$.value[field].$invalid" class="p-error">
      {{ v$.value[field].required.$message.replace('Value', capitalizeFirstLetter(field)) }}
    </small>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { capitalizeFirstLetter } from '@/utils/utils'
import useElements from '@/components/elements_form/useElements'

const
  props = defineProps({
    form: Object,
    field: String,
    disabled: Boolean,
    type: {
      type: String,
      default: 'image'
    },
    accept: String
  }),

  { v$, isLock, store, submitted, labels } = useElements({ props })

const
  fileBase64 = ref(),
  fileSrc = computed(() => fileBase64.value || store.entity?.[props.field]),

  onSelect = async (e) => {
    store.entity[props.field] = e.files[0]

    let reader = new FileReader()
    if (store.entity[props.field]) {
      reader.onloadend = () => {
        fileBase64.value = reader.result
        store.entity['fileBase64'] = reader.result
      }
      reader.readAsDataURL(store.entity[props.field])
    }
  }

</script>
