// import jsonToFormData from 'json-form-data'

export const multiFileUploadConfig = {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
}

export function getFormData(data, previousKey) {
  const formData = new FormData()
  if (data instanceof Object) {
    Object.keys(data).forEach((key) => {
      const value = data[key]
      if (value instanceof Object && !Array.isArray(value)) {
        // return getFormData(formData, value, key)
      }
      if (previousKey) {
        key = `${ previousKey }[${ key }]`
      }

      if (Array.isArray(value)) { //  && key !== 'car_files'
        // console.log(`!!${key}[]`, value)
        value.forEach((val) => {
          console.log(`!!${ key }[]`, val, val instanceof Object)
          if (val instanceof File) {
            formData.append(`${ key }[]`, val)
          } else {
            // formData.append(key, value)
            console.error(val)
            /*
            Object.keys(val).forEach((key2) => {
              const value2 = val[key2]
              console.log(`xxxx ${key2}[]`, value2)
              formData.append(`${key}[][${key2}]`, value2)
            })
            */
          }
        })
      } else {
        formData.append(key, value)
      }
    })
  }
  return formData
}

export function jsonToFormData(data) {
  const formData = new FormData()

  buildFormData(formData, data)

  return formData
}

export function buildFormData(formData, data, parentKey: string | null = null) {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      buildFormData(formData, data[key], parentKey ? `${ parentKey }[${ key }]` : key)
    })
  } else {
    const value = data == null ? '' : data

    formData.append(parentKey, value)
  }
}
