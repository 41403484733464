<template>
  <div :class="layoutContainerClass" @click="onDocumentClick">
    <div class="layout-main">
      <AppTopBar
        :activeInlineProfile="activeInlineProfile" :colorScheme="colorScheme" :items="menu" :menuActive="menuActive" :menuMode="menuMode" :rightMenuActive="rightMenuActive"
        :sidebarActive="staticMenuMobileActive"
        :topbarMenuActive="topbarMenuActive" @topbar-item-click="onTopbarItemClick" @menubutton-click="onMenuButtonClick" @sidebar-mouse-over="onSidebarMouseOver"
        @sidebar-mouse-leave="onSidebarMouseLeave" @toggle-menu="onToggleMenu"
        @change-inlinemenu="onChangeActiveInlineMenu" @menu-click="onMenuClick" @menuitem-click="onMenuItemClick"
        @root-menuitem-click="onRootMenuItemClick" @menu-right-click="onRightMenuButtonClick"
      />

      <AppMenu
        :activeInlineProfile="activeInlineProfile" :colorScheme="colorScheme" :menuActive="menuActive" :menuMode="menuMode" :model="menu" :pinActive="pinActive" :sidebarActive="sidebarActive"
        :sidebarStatic="sidebarStatic"
        @sidebar-mouse-over="onSidebarMouseOver" @sidebar-mouse-leave="onSidebarMouseLeave" @toggle-menu="onToggleMenu" @change-inlinemenu="onChangeActiveInlineMenu" @menu-click="onMenuClick"
        @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"
      />

      <!--
      <AppBreadcrumb
        :menuMode="menuMode" v-model:searchActive="searchActive" v-model:searchClick="searchClick" @menubutton-click="onMenuButtonClick"
        @rightmenu-button-click="onRightMenuButtonClick"
      />
      -->

      <!--
      <AppMenuRight
        v-if="$route.meta.overview"
        :activeInlineProfile="activeInlineProfile" :colorScheme="colorScheme" :menuActive="menuActive" :menuMode="menuMode" :model="menu" :pinActive="pinRActive" :sidebarActive="sidebarRActive"
        :sidebarStatic="sidebarRStatic"
        @sidebar-mouse-over="onSidebarRightMouseOver" @sidebar-mouse-leave="onSidebarRightMouseLeave" @toggle-menu="onToggleMenuRight" @change-inlinemenu="onChangeActiveInlineMenu"
        @menu-click="onMenuRClick"
        @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"
      />
      -->

      <div class="layout-main-content">
        <router-view :key="$route.fullPath" />
      </div>

      <ConfirmDialog :style="{ width: '350px' }" group="patientLock" />

      <AppFooter />
    </div>

  </div>
</template>

<script setup>
import AppTopBar from '@/components/common/AppTopbar.vue'
import AppMenu from '@/components/common/Menu/AppMenu.vue'
// import AppMenuRight from '@/components/common/Menu/AppMenuRight.vue'
import AppFooter from '@/components/common/AppFooter.vue'
// import AppBreadcrumb from '@/components/common/AppBreadcrumb.vue'
import { computed, defineComponent, ref, watch } from 'vue'
import { useAuthStore } from '@/store/auth'
import { useRoute } from 'vue-router'
import { useToast } from 'primevue/usetoast'


const
  emit = defineEmits([
    'layout-mode-change', 'menu-theme', 'layoutModeChange', 'sidebar-mouse-over', 'sidebar-mouse-leave', 'change-color-scheme', 'change-component-theme', 'change-menu-theme', 'change-inlinemenu'
  ]),
  props = defineProps({
    colorScheme: String,
    theme: String
  })

const
  search = ref(false),
  menuClick = ref(false), // TODO - do we need it ?
  menuRClick = ref(false), // TODO - do we need it ?
  searchClick = ref(false),
  searchActive = ref(false),
  menuMode = ref('sidebar'),
  menuActive = ref(false),
  activeInlineProfile = ref(false),
  inlineMenuClick = ref(false),
  overlayMenuActive = ref(false),
  topbarMenuActive = ref(false),
  topbarItemClick = ref(false),
  sidebarActive = ref(!(localStorage.getItem('sidebarActive') && localStorage.getItem('sidebarActive') === 'false')),
  sidebarStatic = ref(!(localStorage.getItem('sidebarStatic') && localStorage.getItem('sidebarStatic') === 'false')),
  pinActive = ref(!(localStorage.getItem('pinActive') && localStorage.getItem('pinActive') === 'false')),
  sidebarRActive = ref(!(localStorage.getItem('sidebarRActive') && localStorage.getItem('sidebarRActive') === 'false')),
  sidebarRStatic = ref(!(localStorage.getItem('sidebarRStatic') && localStorage.getItem('sidebarRStatic') === 'false')),
  pinRActive = ref(!(localStorage.getItem('pinRActive') && localStorage.getItem('pinRActive') === 'false')),
  staticMenuDesktopInactive = ref(false),
  staticMenuMobileActive = ref(false),
  rightMenuClick = ref(false),
  rightMenuActive = ref(false),
  configActive = ref(false),
  configClick = ref(false),
  isRightSidebar = ref(false)

/*
  watch: {
    $route() {
      this.menuActive = false
      this.$toast.removeAllGroups()
    }
  }
*/
const
  route = useRoute(),
  toast = useToast()

watch(() => route, () => {
  menuActive.value = false
  toast.removeAllGroups()
})

const
  onDocumentClick = () => {
    if (!searchClick.value && searchActive.value) {
      onSearchHide()
    }

    if (!topbarItemClick.value) {
      topbarMenuActive.value = false
    }

    if (!menuClick.value) {
      if (overlayMenuActive.value || staticMenuMobileActive.value) {
        overlayMenuActive.value = false
        staticMenuMobileActive.value = false
      }

      hideOverlayMenu()
      unblockBodyScroll()
    }

    if (menuRClick.value || !rightMenuClick.value && !menuClick.value) {
      rightMenuActive.value = false
    }

    if (configActive.value && !configClick.value) {
      configActive.value = false
    }

    topbarItemClick.value = false
    menuClick.value = false
    menuRClick.value = false
    configClick.value = false
    rightMenuClick.value = false
    searchClick.value = false
    inlineMenuClick.value = false
  },
  onSearchHide = () => {
    searchActive.value = false
    searchClick.value = false
  },
  onSidebarMouseOver = () => {
    if (menuMode.value === 'sidebar' && !sidebarStatic.value) {
      sidebarActive.value = isDesktop()
      setTimeout(() => {
        pinActive.value = isDesktop()
      }, 200)
    }
  },
  onSidebarMouseLeave = () => {
    if (menuMode.value === 'sidebar' && !sidebarStatic.value) {
      setTimeout(() => {
        sidebarActive.value = false
        pinActive.value = false
      }, 250)
    }
  },

  onSidebarRightMouseOver = () => {
    if (menuMode.value === 'sidebar' && !sidebarRStatic.value) {
      sidebarRActive.value = isDesktop()
      setTimeout(() => {
        pinRActive.value = isDesktop()
      }, 200)
    }
  },
  onSidebarRightMouseLeave = () => {
    if (menuMode.value === 'sidebar' && !sidebarRStatic.value) {
      setTimeout(() => {
        sidebarRActive.value = false
        pinRActive.value = false
      }, 250)
    }
  },
  hideOverlayMenu = () => {
    overlayMenuActive.value = false
    staticMenuMobileActive.value = false
  },
  onMenuButtonClick = (e) => {
    menuClick.value = true
    menuRClick.value = true

    if (isOverlay()) {
      overlayMenuActive.value = !overlayMenuActive.value
    }

    if (isDesktop()) {
      staticMenuDesktopInactive.value = !staticMenuDesktopInactive.value
    } else {
      staticMenuMobileActive.value = !staticMenuMobileActive.value
    }

    e.preventDefault()
  },
  onTopbarItemClick = (e) => {
    topbarItemClick.value = true
    topbarMenuActive.value = !topbarMenuActive.value
    hideOverlayMenu()
    e.preventDefault()
  },
  onRightMenuButtonClick = () => {
    rightMenuClick.value = !rightMenuClick.value
    rightMenuActive.value = !rightMenuActive.value
  },
  onRightMenuClick = () => {
    rightMenuClick.value = true
  },
  onMenuClick = () => {
    menuClick.value = true
  },
  onMenuRClick = () => {
    menuRClick.value = true
  },
  onRootMenuItemClick = () => {
    menuActive.value = !menuActive.value
  },
  onMenuItemClick = (e) => {
    if (!e.item.items) {
      hideOverlayMenu()
      // EventBus.emit('reset-active-index')
    }
  },
  onConfigClick = () => {
    configClick.value = true
  },
  onConfigButtonClick = (e) => {
    configActive.value = !configActive.value
    configClick.value = true
    e.preventDefault()
  },
  onChangeRightMenuActive = (active) => {
    rightMenuActive.value = active
  },
  onChangeMenuTheme = (theme) => {
    emit('change-menu-theme', theme)
  },
  onChangeComponentTheme = (theme) => {
    emit('change-component-theme', theme)
  },
  onChangeColorScheme = (scheme) => {
    emit('change-color-scheme', scheme)
  },
  onToggleMenu = (e) => {
    menuClick.value = true

    if (overlayMenuActive.value) {
      overlayMenuActive.value = false
    }

    if (sidebarActive.value) {
      sidebarStatic.value = !sidebarStatic.value
    }

    localStorage.setItem('sidebarStatic', sidebarStatic.value)
    localStorage.setItem('sidebarActive', sidebarStatic.value)
    localStorage.setItem('pinActive', sidebarStatic.value)

    e.preventDefault()
  },

  onToggleMenuRight = (e) => {
    menuRClick.value = true

    if (overlayMenuActive.value) {
      overlayMenuActive.value = false
    }

    if (sidebarRActive.value) {
      sidebarRStatic.value = !sidebarRStatic.value
    }

    localStorage.setItem('sidebarRStatic', sidebarRStatic.value)
    localStorage.setItem('sidebarRActive', sidebarRStatic.value)
    localStorage.setItem('pinRActive', sidebarRStatic.value)

    e.preventDefault()
  },
  onChangeActiveInlineMenu = () => {
    activeInlineProfile.value = !activeInlineProfile.value
  },
  blockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.add('blocked-scroll')
    } else {
      document.body.className += ' blocked-scroll'
    }
  },
  unblockBodyScroll = () => {
    if (document.body.classList) {
      document.body.classList.remove('blocked-scroll')
    } else {
      document.body.className = document.body.className.replace(new RegExp('(^|\\b)' +
        'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
    }
  },
  isOverlay = () => {
    return menuMode.value === 'overlay'
  },
  isStatic = () => {
    return menuMode.value === 'static'
  },
  isSidebar = () => {
    return menuMode.value === 'sidebar'
  },
  isDesktop = () => {
    return window.innerWidth > 991
  },
  isMobile = () => {
    return window.innerWidth <= 991
  },

  menu = computed(() => {
    const store = useAuthStore()
    return [
      { label: 'Dashboard', icon: 'pi pi-fw pi-home', to: '/' },
      {
        label: 'Heroes Content', icon: 'pi pi-fw pi-prime', items: [
          { label: 'Races', icon: 'pi pi-fw pi-reddit', to: '/races' },
          { label: 'Heroes', icon: 'pi pi-fw pi-prime', to: '/heroes' },
          { label: 'Arts', icon: 'pi pi-fw pi-shield', to: '/arts' },
          { label: 'Voices', icon: 'pi pi-fw pi-volume-up', to: '/voices' },
          { label: 'Cards', icon: 'pi pi-fw pi-money-bill rotate-90', to: '/cards' }
        ]
      },
      // {label: 'Users Content', icon: 'pi pi-fw pi-gift', items: []},
      {
        label: 'People', icon: 'pi pi-fw pi-users', items: [
          // { label: 'Streamers', icon: 'pi pi-fw pi-desktop', to: '/streamers' },
          { label: 'Users', icon: 'pi pi-fw pi-users', to: '/users' },
          { label: 'Badge Types', icon: 'pi pi-fw pi-tags', to: '/badges' },
          { label: 'Messages', icon: 'pi pi-fw pi-envelope', to: '/messages' }
        ]
      },
      {
        label: 'Content', icon: 'pi pi-fw pi-book', items: [
          { label: 'News', icon: 'pi pi-fw pi-book', to: '/news' },
          { label: 'Gifs', icon: 'pi pi-fw pi-images', to: '/gifs' },
          { label: 'AudioMems', icon: 'pi pi-fw pi-microphone', to: '/audioMems' },
          { label: 'Initiatives', icon: 'pi pi-fw pi-heart-fill', to: '/initiatives' },
          { label: 'Seasons', icon: 'pi pi-fw pi-slack', to: '/seasons' },
          { label: 'Documents', icon: 'pi pi-fw pi-file-edit', to: '/documents' }
        ]
      },
      {
        label: 'Payments', icon: 'pi pi-fw pi-wallet', items: [
          { label: 'Shop Items', icon: 'pi pi-fw pi-shopping-cart', to: '/shopItems' },
          { label: 'Transactions', icon: 'pi pi-fw pi-money-bill', to: '/transactions' },
          { label: 'Referrer requests', icon: 'pi pi-fw pi-clock', to: '/referrerRequests' }
        ]
      }
      // ...store.isAdmin ? [{ label: 'Technicians', icon: 'pi pi-fw pi-users', to: '/technicians' }] : [],
      // ...store.isSuperAdmin ? [{
      //   label: 'Patients List', icon: 'pi pi-fw pi-users', to: '/patients'
      //   // items: state.patient.lastViewPatients
      // }] : []
    ]
  }),
  layoutContainerClass = computed(() => {
    return [
      'layout-wrapper',
      {
        'layout-overlay-active': overlayMenuActive.value,
        'layout-active': menuActive.value,
        'layout-mobile-active': staticMenuMobileActive.value,
        'layout-sidebar': menuMode.value === 'sidebar',
        'layout-sidebar-right': route.meta.overview,
        'layout-sidebar-static': menuMode.value === 'sidebar' && sidebarStatic.value,
        'layout-sidebar-right-static': menuMode.value === 'sidebar' && sidebarRStatic.value,
        'layout-static-inactive': staticMenuDesktopInactive.value && menuMode.value === 'static',
        'p-ripple-disabled': true
      }
    ]
  })
</script>
