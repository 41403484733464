import api from '../plugins/axios-instance'

import { defineStore } from 'pinia'
import { computed, ref } from 'vue'
import { useAdminUserStore } from '@/store/adminUsers'

const userLocal = JSON.parse(localStorage.getItem('user'))

export const useAuthStore = defineStore('auth', () => {
  const
    store = useAdminUserStore(),
    loggedIn = ref(!!userLocal),
    isSuperAdmin = computed(() => store.entity && store.entity.role === 'super-admin'),
    isAdmin = computed(() => store.entity && store.entity.role === 'admin')

  store.entity = userLocal ?? null

  const
    loginSuccess = (response) => {
      localStorage.setItem('user', JSON.stringify(response.user))
      localStorage.setItem('token', response.token)
      loggedIn.value = true
      store.entity = response.user
    },

    loginFailure = () => {
      loggedIn.value = false
      store.entity = null
    }

  const
    login = async user => {
      try {
        const response = await api.post('admin/login', user)
        if (response.code === 200) {
          loginSuccess(response.data)
        } else {
          loginFailure()
        }
        return response
      } catch (e) {
        loginFailure()
        return e
      }
    },

    logout = () => {
      localStorage.removeItem('user')
      localStorage.removeItem('token')
      localStorage.removeItem('activeIndex')
      localStorage.removeItem('activeIndexMain')
      loginFailure()
    }

  return { loggedIn, isSuperAdmin, isAdmin, login, logout }
})
