<template>
  <ul v-if="items" role="menu">
    <template v-for="(item, i) of items">
      <li
        v-if="visible(item) && !item.separator" :key="item.label || i"
        :class="[{'layout-root-menuitem': root, 'active-menuitem':
        ((Number(activeIndex) === i && !root) || (Number(activeIndexMain) === i && root)) &&
        !item.disabled}]"
        role="menuitem"
      >
        <div v-if="root && isStatic()" class="layout-menuitem-text">{{ item.label }}</div>
        <router-link
          v-if="item.to"
          v-ripple
          :class="[item.class, 'p-ripple', { 'p-disabled': item.disabled }, '_menu-toggle-link']"
          :style="item.style"
          :target="item.target"
          :to="item.to"
          active-class="active-route"
          exact
          @click="onMenuItemClick($event, item, i)"
          @mouseenter="onMenuItemMouseEnter(i)"
        >
          <!--
          <span v-if="item.avatar">
            <Avatar v-if="item.img" :image="'data:image/png;base64,' + item.img" alt="avatar" class="user-image mr-2" size="xlarge" shape="circle" />
            <Avatar v-else icon="pi pi-user" class="p-mr-2" size="xlarge" shape="circle" />
          </span>
          -->
          <i :class="['layout-menuitem-icon', item.icon]"></i>
          <span class="layout-menuitem-text">{{ item.label }}</span>
        </router-link>
        <i v-if="item.to && item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler _menu-toggle-icon" @click="onMenuItemClick($event, item, i)"></i>
        <a
          v-if="!item.to" v-ripple :class="[item.class, 'p-ripple', {'p-disabled': item.disabled}]" :href="item.url||'#'" :style="item.style"
          :target="item.target" role="menuitem" @click="onMenuItemClick($event,item,i)" @mouseenter="onMenuItemMouseEnter(i)"
        >
          <i :class="['layout-menuitem-icon', item.icon]"></i>
          <span class="layout-menuitem-text">{{ item.label }}</span>
          <i v-if="item.items" class="pi pi-fw pi-angle-down layout-submenu-toggler"></i>
        </a>
        <transition name="layout-menu">
          <appsubmenu
            v-show="item.items && root && isStatic() ? true : Number(activeIndexMain) === i" :items="visible(item) && item.items"
            :menuActive="menuActive" :menuMode="menuMode" :parentMenuItemActive="Number(activeIndex) === i" @menuitem-click="$emit('menuitem-click', $event)"
          />
        </transition>
      </li>
    </template>
  </ul>
</template>

<script>

export default {
  name: 'appsubmenu',
  emits: ['root-menuitem-click', 'menuitem-click'],
  props: {
    items: Array,
    menuMode: String,
    menuActive: {
      type: Boolean,
      default: false
    },
    root: {
      type: Boolean,
      default: false
    },
    parentMenuItemActive: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      activeIndex: localStorage.getItem('activeIndex') ? localStorage.getItem('activeIndex') : null,
      activeIndexMain: localStorage.getItem('activeIndexMain') ? localStorage.getItem('activeIndexMain') : null
    }
  },
  methods: {
    onMenuItemClick(event, item, index) {
      console.log('LOG -> ', event, item, index)
      if (item.disabled) {
        event.preventDefault()
        return
      }
      //execute command
      if (item.command) {
        item.command({ originalEvent: event, item: item })
        event.preventDefault()
      }
      if (item.items) {
        event.preventDefault()
      }
      if (this.root) {
        this.$emit('root-menuitem-click', { originalEvent: event })
      }
      if (item.items) {
        this.activeIndex = index === Number(this.activeIndex) ? null : index
        this.activeIndexMain = index === Number(this.activeIndexMain) ? null : index
        localStorage.setItem('activeIndexMain', this.activeIndex)
      } else {
        this.activeIndex = index
        localStorage.setItem('activeIndex', this.activeIndex)
        
        if (this.menuMode !== 'sidebar') {
          const ink = this.getInk(event.currentTarget)
          if (ink) {
            this.removeClass(ink, 'p-ink-active')
          }
        }
      }
      this.$emit('menuitem-click', {
        originalEvent: event,
        item: item
      })
    },
    onMenuItemMouseEnter(index) {
      if (this.root && this.menuActive && !this.isMobile()) {
        this.activeIndex = index
        // localStorage.setItem('activeIndex', this.activeIndex)
      }
    },
    visible(item) {
      return typeof item.visible === 'function' ? item.visible() : item.visible !== false
    },
    isMobile() {
      return window.innerWidth <= 991
    },
    isStatic() {
      return this.menuMode === 'static'
    },
    getInk(el) {
      for (let i = 0; i < el.children.length; i++) {
        if (typeof el.children[i].className === 'string' && el.children[i].className.indexOf('p-ink') !== -1) {
          return el.children[i]
        }
      }
      return null
    },
    removeClass(element, className) {
      if (element.classList)
        element.classList.remove(className)
      else
        element.className = element.className.replace(new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
    }
  }
}
</script>
