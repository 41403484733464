<template>
  <div class="layout-footer flex justify-content-center">
    <div class="footer-logo-container">
      <!--
      <img id="footer-logo" :src="'/img/logo-g-v.svg'" alt="G-medical" />
      <span class="app-name">G-MEDICAL</span>
      -->
    </div>
    <span class="copyright">&#169; Donate-Heroes - {{ new Date().getFullYear() }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const version = computed(() => process?.env?.VUE_APP_SW_VERSION)
</script>

