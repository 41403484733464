import CrInput from '@/components/elements_form/CrInput.vue'
import CrTextarea from '@/components/elements_form/CrTextarea.vue'
import CrEditor from '@/components/elements_form/CrEditor.vue'
import CrDropdown from '@/components/elements_form/CrDropdown.vue'
import CrDate from '@/components/elements_form/CrDate.vue'
import CrSelectButton from '@/components/elements_form/CrSelectButton.vue'
import CrPhoneCountry from '@/components/elements_form/CrPhoneCountry.vue'
import CrFileUpload from '@/components/elements_form/CrFileUpload.vue'

const applyCommon = (app) => {
  const Vue = app
  Vue.component('CrInput', CrInput)
  Vue.component('CrTextarea', CrTextarea)
  Vue.component('CrEditor', CrEditor)
  Vue.component('CrDropdown', CrDropdown)
  Vue.component('CrDate', CrDate)
  Vue.component('CrSelectButton', CrSelectButton)
  Vue.component('CrPhoneCountry', CrPhoneCountry)
  Vue.component('CrFileUpload', CrFileUpload)
}


export default applyCommon
