import { defineStore } from 'pinia'
import { ref } from 'vue'

export const useTemporaryStore = defineStore('temporary', () => {
  const
    form = ref({
      $v: {}
    }),
    setForm = (payload) => {
      form.value = payload
    }

  return { setForm, form }
})
