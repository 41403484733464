<template>
  <div class="field mb-5 phone-country">
    <span class="fluid p-float-label">
      <Dropdown
        v-model="inputCountry"
        :options="countries"
        optionLabel="name"
        optionValue="value"
        style="width: 6.5rem"
        @change="$emit('update:phone_country', $event.value)"
      >
         <template #value="slotProps">
          <span class="vti__selection">
            <span :class="'vti__flag ' + getCountryCode(slotProps.value)"></span>
            <span>{{ slotProps.value }}</span>
          </span>
        </template>
        <template #option="slotProps">
          <span class="vti__selection">
            <span :class="'vti__flag ' + slotProps.option.code"></span>
            <span>{{ slotProps.option.name }} +{{ slotProps.option.value }}</span>
          </span>
        </template>
      </Dropdown>
      <InputText
        :id="field"
        v-model="input"
        :class="{'p-invalid': v$.$invalid && submitted}"
        :value="phone_number"
        style="width: 8rem"
        type="text"
        @input="$emit('update:phone_number', $event.target.value)"
      />
      <input class="p-filled" type="hidden">
      <label :for="field">{{ label }}</label>
    </span>
    <span v-if="v$.$error && submitted" class="block pb-2">
      <span v-for="(error, i) of v$.$errors" :key="i">
        <small class="p-error">{{ error.$message.replace('Value', label) }}</small>
      </span>
    </span>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { countries } from '@/constants/countryPhoneCodes'

const props = defineProps({
  phone_number: String,
  form: Object,
  phone_country: String,
  parent: String,
  parent2: String,
  field: String,
  placeholder: String,
  maxlength: {
    type: Number,
    default: 256
  },
  required: {
    type: Boolean,
    default: false
  },
  disabled: {
    type: Boolean,
    default: false
  },
  callback: Function
})

defineEmits(['update:phone_number', 'update:phone_country'])

const
  input = ref(props.phone_number),
  inputCountry = ref(props.phone_country),
  label = computed(() =>
    props.parent2
      ? props.form.labels[props.parent2][props.parent][props.field]
      : (props.parent
        ? props.form.labels[props.parent][props.field]
        : props.form.labels[props.field])
  ),
  v$ = computed(() => props.parent2
    ? props.form.v$.value.value[props.parent2][props.parent][props.field] || {}
    : (props.parent
      ? props.form.v$.value.value[props.parent][props.field] || {}
      : (props.form.v$.value.value[props.field] || {}))
  ),
  submitted = computed(() => props.form.submitted.value),
  
  getCountryCode = (value) => {
    const country = countries.find(x => x.value === value)
    return country ? country['code'] : ''
  }


watch(() => props.modelValue, (val) => {
  // input.value = val
})
</script>

