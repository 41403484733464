import { createRouter, createWebHistory } from 'vue-router'
import { useAuthStore } from './store/auth.js'

const routesTemplate = (name, path) => ([
  {
    path: `/:entity(${ path })`,
    name,
    component: () => import (`./pages/${ path }/list.vue`)
  },
  {
    path: `/:entity(${ path })/add`,
    name: `${ name }Add`,
    component: () => import (`./pages/${ path }/edit.vue`)
  },
  {
    path: `/:entity(${ path })/:id`,
    name: `${ name }Edit`,
    component: () => import (`./pages/${ path }/edit.vue`)
  }
])

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    exact: true,
    component: () => import ('./pages/dashboard.vue')
  },

  ...routesTemplate('Voice', 'voices'),
  ...routesTemplate('Hero', 'heroes'),
  ...routesTemplate('Race', 'races'),
  ...routesTemplate('Art', 'arts'),
  ...routesTemplate('Card', 'cards'),
  ...routesTemplate('Streamer', 'streamers'),
  ...routesTemplate('News', 'news'),
  ...routesTemplate('Documents', 'documents'),
  ...routesTemplate('Gif', 'gifs'),
  ...routesTemplate('AudioMem', 'audioMems'),
  ...routesTemplate('Initiatives', 'initiatives'),
  ...routesTemplate('Seasons', 'seasons'),
  ...routesTemplate('Users', 'users'),
  ...routesTemplate('Badges', 'badges'),
  ...routesTemplate('Transactions', 'transactions'),
  ...routesTemplate('ShopItems', 'shopItems'),
  ...routesTemplate('ReferrerRequests', 'referrerRequests'),
  ...routesTemplate('Messages', 'messages'),

  {
    path: '/profile/edit/:id',
    name: 'ProfileEdit',
    component: () => import ('./pages/adminUsers/edit.vue'),
    meta: {
      breadcrumb: [{ parent: 'AdminUsers', label: 'Admin information' }],
      adminId: true
    }
  },
  {
    path: '/login',
    name: 'LoginView',
    component: () => import ('./pages/login.vue'),
    meta: {
      layout: 'AppLayoutLogin'
    }
  },
  {
    path: '/404',
    name: '404',
    component: () => import('./pages/404.vue'),
    meta: {
      layout: 'AppLayoutLogin'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.length === 0) {
    next('/404')
    return
  }

  const store = useAuthStore()

  const
    publicPages = ['/login', '/404'],
    authRequired = !publicPages.includes(to.path),
    loggedIn = localStorage.getItem('token') && store.loggedIn

  // trying to access a restricted page + not logged in redirect to login page
  if (authRequired && !loggedIn) {
    next('/login')
  } else {
    next()
  }
})

export default router
