<template>
  <div class="field mb-5">
    <span class="p-float-label">
      <template v-if="lang">
        <div class="p-float-label _label"><label :for="field">{{ labels['_i18n'][lang][field] }}</label></div>
        <!-- plugins="advcode" -->
        <Editor v-model="store.entity['_i18n'][lang][field]" :readonly="disabled || isLock"
                api-key="s9h2ar2a07qqw7h804b1w9ppnkc5lqf6o79hlupmtjvs59hv"
                content_css="dark"
                height="320"
                plugins="searchreplace autolink directionality visualblocks visualchars image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap linkchecker emoticons autosave fullscreen"
                skin="oxide-dark"
        />
      </template>
      <template v-else>
        <div class="p-float-label _label"><label :for="field">{{ labels[field] }}</label></div>
        <Editor v-model="store.entity[field]"
                :readonly="disabled || isLock"
                api-key="s9h2ar2a07qqw7h804b1w9ppnkc5lqf6o79hlupmtjvs59hv"
                content_css="dark"
                height="320"
                plugins="searchreplace autolink directionality visualblocks visualchars image link media codesample table charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap linkchecker emoticons autosave fullscreen" />
      </template>
    </span>
    <div>
      <small v-if="isError" class="p-error">
        {{
          lang
            ? v$.value['_i18n'][lang][field].required.$message.replace('Value', labels['_i18n'][lang][field])
            : v$.value[field].required.$message.replace('Value', labels[field])
        }}
      </small>
    </div>
  </div>
</template>
<script setup>
import useElements from '@/components/elements_form/useElements'

const
  props = defineProps({
    form: Object,
    field: String,
    placeholder: String,
    lang: String,
    type: {
      type: String,
      default: 'text'
    },
    mode: String,
    currency: {
      type: String,
      default: 'USD'
    },
    required: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    }
  }),

  { v$, isLock, store, submitted, labels, isError } = useElements({ props })

</script>
