<template>
  <div
    :class="{'layout-sidebar-active': sidebarActive}" class="layout-menu-wrapper"
    @click="onMenuClick" @mouseleave="onSidebarMouseLeave" @mouseover="onSidebarMouseOver"
  >
    <div v-if="isMobile() && sidebarActive" class="layout-topbar-left">
      <a class="topbar-menu-button close-button" href="#" @click="onMenuButtonClick($event)">
        <i class="pi pi-arrow-left"></i>
      </a>
    </div>
    <div class="menu-logo">
      
      <a class="logo" href="#">
        <img :src="'/img/icon-logo.png'" class="login-logo" />
      </a>
      
      <a class="app-name" href="#">
        <img :src="'/img/text-logo.png'" class="login-logo" />
      </a>
      
      <a class="menu-pin" href="#" @click="onToggleMenu">
        <span v-if="isSidebar() && !sidebarStatic && pinActive" class="pi pi-window-maximize"></span>
        <span v-if="isSidebar() && sidebarStatic && pinActive" class="pi pi-window-minimize"></span>
      </a>
    </div>
    
    <div class="layout-menu-container">
      <AppSubmenu
        :items="model" :menuActive="menuActive" :menuMode="menuMode" :parentMenuItemActive="true" :root="true" class="layout-menu"
        @menu-click="onMenuClick" @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick"
      />
    </div>
    
    <AppInlineMenu :activeInlineProfile="activeInlineProfile" :menuMode="menuMode" @change-inlinemenu="onChangeActiveInlineMenu"></AppInlineMenu>
  </div>
</template>

<script>
import AppSubmenu from './AppSubmenu'
import AppInlineMenu from './AppInlineMenu'

export default {
  name: 'AppMenu',
  emits: ['menu-click', 'menu-left-click', 'menuitem-click', 'root-menuitem-click', 'sidebar-mouse-over', 'sidebar-mouse-leave', 'toggle-menu', 'change-inlinemenu'],
  props: {
    model: Array,
    menuMode: String,
    colorScheme: String,
    menuActive: {
      type: Boolean,
      default: false
    },
    activeInlineProfile: {
      type: Boolean,
      default: false
    },
    sidebarActive: {
      type: Boolean,
      default: false
    },
    sidebarStatic: {
      type: Boolean,
      default: false
    },
    pinActive: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AppSubmenu,
    AppInlineMenu
  },
  methods: {
    onSidebarMouseOver() {
      this.$emit('sidebar-mouse-over')
    },
    onSidebarMouseLeave() {
      this.$emit('sidebar-mouse-leave')
    },
    onMenuClick(event) {
      this.$emit('menu-click', event)
    },
    onMenuButtonClick(event) {
      this.$emit('menu-left-click', event)
    },
    onMenuItemClick(event) {
      this.$emit('menuitem-click', event)
    },
    onRootMenuItemClick(event) {
      this.$emit('root-menuitem-click', event)
    },
    onToggleMenu(event) {
      this.$emit('toggle-menu', event)
    },
    onChangeActiveInlineMenu() {
      this.$emit('change-inlinemenu')
    },
    isStatic() {
      return this.menuMode === 'static'
    },
    isSidebar() {
      return this.menuMode === 'sidebar'
    },
    isDesktop() {
      return window.innerWidth > 991
    },
    isMobile() {
      return window.innerWidth <= 991
    }
  }
}
</script>

