// import { MazPhoneNumberInput } from 'maz-ui'
// import VuePhoneNumberInput from 'vue-phone-number-input'
// import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import VueTelInput from 'vue-tel-input'
import 'vue-tel-input/vue-tel-input.css'

const applyMazUi = (app) => {
  // app.use(VueTelInput)
  // app.component('vue-tel-input', VueTelInput)
}


export default applyMazUi
