import api from '@/plugins/axios-instance'
import { jsonToFormData, multiFileUploadConfig } from '@/utils/files'
import { ref } from 'vue'

export default (code) => {
  const url = `admin/${ code }`

  const
    entities = ref(null),
    pagination = ref({}),
    entity = ref<any>(null),
    list = ref([])

  const
    setEntity = (payload) => {
      entity.value = payload.data
      if (payload?.data?.date) {
        entity.value.date = new Date(payload.data.date)
      }
      if (payload?.data?.dateFrom) {
        entity.value.dateFrom = new Date(payload.data.dateFrom)
      }
      if (payload?.data?.dateTo) {
        entity.value.dateTo = new Date(payload.data.dateTo)
      }
    },
    setEntities = (payload) => {
      // console.log('>>> setEntities: ', payload)
      const { docs, totalDocs, totalPages, page } = payload.data
      entities.value = docs
      pagination.value = { totalDocs, totalPages, page }
    },
    setList = (payload) => {
      list.value = payload.data
    }

  const
    findAll = async (params) => {
      const resp = await api.post(`${ url }/getAll`, params)
      setEntities(resp)
    },

    findOne = async (id) => {
      const resp = await api.get(`${ url }/${ id }`)
      setEntity(resp)
    },

    createUpdate = async () => {
      if (entity.value['_id']) {
        delete entity.value['createdBy']
        delete entity.value['createdAt']
      }
      const
        data = { ...entity.value },
        formData = jsonToFormData(data)

      return await api.post(`${ url }`, formData, multiFileUploadConfig)
    },

    remove = id => api.delete(`${ url }/${ id }`),

    findList = async () => {
      const resp = await api.get(`${ url }/list`)
      setList(resp)
    }

  return {
    entities,
    entity,
    pagination,
    list,
    findAll,
    findOne,
    createUpdate,
    remove,
    findList,
    setEntity
  }
}
